import React from "react"
import H3 from "./Utility/heading3"

export default props => (
    <div class="cta--social">
        <div class="content">
            <H3 headingText1={props.headingText1} headingText2={props.headingText2} headingColor1={props.headingColor1} headingColor2={props.headingColor2} />
            <p class="social-body" style={{ color: props.bodyColor }}>{props.bodyText}</p>
            <ul class="social">
                <li class="social-item"><a class="social-link" href="https://www.instagram.com/raisedandrooted/" target="_blank"><img src="/instagram-logo-green.svg" alt="Instagram" /></a></li>
                <li class="social-item"><a class="social-link" href="https://www.facebook.com/raisedandrooted/" target="_blank"><img src="/facebook-logo-green.svg" alt="Facebook" /></a></li>
            </ul>     
        </div>
        <div class="media">
            <img src={props.imgSrc} alt="" />
        </div>
    </div>
)