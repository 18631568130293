import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Helmet from 'react-helmet';

import Intro from '../components/page-intro';
import { ConsumerAffairsForm } from '@tyson-foods/react-component-library';
import ContactItem from '../components/contact-item';
import SocialCTA from "../components/cta--social";

export default class ContactPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loaded: false
        }
    }
    async componentDidMount() {
        this.setState({loaded: true})
    }
    content() {
        return (<React.Fragment>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact Raised & Rooted™ by phone, mail or filling out our online form with any questions or comments you may have. Or follow us on social media!"/>
            </Helmet>
            <Layout>
                <Intro bgImage="/illustrated-background.svg" headingText1="Contact Us" headingColor1="#006B2D" bodyColor="#006B2D" bodyText="Now that you’ve heard from us, we’d love to hear from you. If you have any comments or questions, reach out and we’ll happily answer."/>
                <div class="consumer-affairs">
                    <ConsumerAffairsForm buttonColor="secondary" formHandlerApiUri={process.env.GATSBY_FORM_HANDLER_API_URL} formHandlerApiKey={process.env.GATSBY_FORM_HANDLER_API_KEY}/>
                </div>
                <div class="contact-items">
                    <h4>Customer Service</h4>
                    <h5>Give us a shout by phone or mail:</h5>
                    <div class="h-card">                    
                        <p>Call us at: 1-833-427-4747</p><br/>
                        <p class="p-name">Raised & Rooted Consumer Relations</p>
                        <p class="p-adr h-adr">
                            <span class="p-street-address">PO Box 2020</span><br/>
                            <span class="p-locality">Springdale</span>,
                            <span class="p-region"> AR </span>
                            <span class="p-postal-code"> 72765</span>
                        </p>
                    </div>
                </div>

                <SocialCTA imgSrc="/RaisedRooted-ContactUs-GiveUsAFollowImage-BuffaloChickenSand-625x417.png" headingText1="Give us a" headingText2="Follow!" headingColor1="#006B2D" bodyColor="#006B2D" bodyText="Check us out on social media. Warning: You might get hungry."/>
            </Layout>
        </React.Fragment>)
    }
    render(){
        return(
            this.state.loaded ? this.content() : null
        )
    }
}
